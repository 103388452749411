<template>
    <div class="w-p-100 h-p-100 bg-f3">
      <div class="h-54 w-p-100 flex alignCenter justifyBetween">
        <van-search
          class="w-p-100"
          v-model="query.update_by"
          show-action
          background="#F3F3F3"
          @search="onSearch(1)"
          placeholder="关键字查询"
        >
          <template #action>
            <div class="flex alignCenter">
              <van-button size="mini" @click="onSearch(1)" type="primary">查询</van-button>
              <van-button size="mini" @click="showSidebar" type="primary">更多条件</van-button>
              <van-button size="mini" @click="addOrUpdate(0)" v-if="permission.insertflag == 1" type="primary">新增</van-button>
            </div>
          </template>
        </van-search>
      </div>
      <div class="h-calc-54">
        <van-pull-refresh
          v-model="loading"
          @refresh="refresh"
          @load="onLoad"
          :finished="finished"
          finished-text="没有更多了"
          class="h-calc-45 scroll-y"
        >
          <div v-if="list.length > 0" class="w-p-100 h-p-100">
            <div
              class="w-calc-48 Fbg lP-24 rP-24 bM-4 tP-5 bP-5 relative"
              v-for="item in list"
              :key="item"
            >
              <div class="h-45 flex alignCenter justifyBetween">
                <div class="size-20">{{ dayjs(item.risk_judgement_date).format('YYYY-MM-DD') }}</div>
                <div class="size-18 color-80">{{ item.status }}</div>
              </div>
              <div class="h-35 flex alignCenter size-14 color-80">
                {{ item.update_by }}
              </div>
              <div
                class="h-35 flex alignCenter justifyBetween size-14 color-80"
              >
                <div class="flex r-24 b-12 alignCenter justifyEnd">
                  <div
                    class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff padding-5 lM-5 radius-4"
                    @click="addOrUpdate(item.id)"
                  >
                    详情
                  </div>
                  <div
                    v-if="permission.deleteflag == 1&&item.status=='待提交'"
                    class="size-14 flex alignCenter justifyCenter bg-ff0000 color-fff padding-5 lM-5 radius-4"
                    @click="del(item.id)"
                  >
                    删除
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <van-empty description="暂无数据"></van-empty>
          </div>
        </van-pull-refresh>
      </div>
    </div>
    <van-popup v-model:show="sideVisible" position="left" :overlay="false" @close="closeSidebar">
        <div class="margin-0 bg-transparent lP-24 rP-24 tP-40">
            状态
            <van-field
            is-link
            v-model="state.status"
            name="status"
            @click="picker.status = true"
            />
            日期
            <div class="flex alignCenter">
                <van-field
                class="searchInputBox"
                v-model="query.startdate"
                name="calendar"
                placeholder="点击选择日期"
                @click="showCalendar = true"
                />~
                <van-field
                class="searchInputBox"
                v-model="query.enddate"
                name="calendar"
                placeholder="点击选择日期"
                @click="showCalendar2 = true"
                />
            </div>
           
            <!-- 下拉内容 -->
            <van-popup v-model:show="picker.status" position="bottom">
            <van-picker
                :columns="options.status"
                @confirm="
                data => {
                    confirm(data, 'status');
                }
                "
                :columns-field-names="{ text: 'label', value: 'value' }"
                @cancel="picker.status = false"
            />
            </van-popup>
            提交人
            <van-field
            v-model="query.update_by"
            name="update_by"
            placeholder="提交人"
            />
        </div>
        <div class="flex justifyCenter">
            <van-button size="mini" @click="onSearch(1)" type="primary">查询</van-button>
            <van-button size="mini" @click="closeSidebar()" type="info">关闭</van-button>
      </div>

            
    </van-popup>
    <van-calendar
      v-model:show="showCalendar"
      @confirm="calendarOnConfirm"
      :min-date="new Date('2000-01-01')"
    />
    <van-calendar
      v-model:show="showCalendar2"
      @confirm="calendarOnConfirm2"
      :min-date="new Date('2000-01-01')"
    />

    <van-dialog
            v-model:show="dateVisible"
            show-cancel-button cancel-button-text="关闭" title="选择日期"
            @confirm = "submit()"
    >
    <div class="h-100 scroll-y w-p-100 flex alignCenter">
      <van-field
          v-model="uploadForm.risk_judgement_date"
          name="calendar"
          placeholder="点击选择日期"
          label="日期"
          @click="showCalendar3 = true"
          />
    </div>
    </van-dialog>

    <van-calendar
      v-model:show="showCalendar3"
      @confirm="calendarOnConfirm3"
      :min-date="new Date('2000-01-01')"
    />
</template>
<script setup>
/* eslint-disable */
import { onMounted, reactive ,ref} from 'vue';
import http from '../../../api/http';
import { useRouter,useRoute } from 'vue-router';
import dayjs from 'dayjs';
import {Toast,Dialog} from 'vant'
const router = useRouter();
const route = useRoute();

import global from '../../../api/global';
const permission = reactive(global.getPagePermission(route.name));

const showCalendar = ref(false);
const showCalendar2 = ref(false);
const sideVisible = ref(false);
const showSidebar = () =>{
    sideVisible.value = true;
}
const closeSidebar = () =>{
    sideVisible.value = false;
}
const list = ref([]);
const query = reactive({
    method:'query',
    queryId:3007,
    t:'RISK_JUDGEMENT',
    type:'5',
    status:'',
    startdate:'',
    enddate:'',
    update_by:'',
    orderby:'',
    sort:'',
    page:1,
    pageSize:20
});
const state = reactive({
    method:'query',
    queryId:3007,
    t:'RISK_JUDGEMENT',
    type:'5',
    status:'',
    startdate:'',
    enddate:'',
    update_by:'',
    orderby:'',
    sort:'',
    page:1,
    pageSize:20
});
const picker = reactive({
    status:false,
});
const options = reactive({
    status:[],
}); 
const confirm = (data,type) =>{
    query[type] = data.value;
    state[type] = data.label;
    picker[type] = false;        
}
const getSelectList = () =>{
    http.get({
        method:'listMultiPardata',
        t:'RISK_JUDGEMENT',
        prop:'status',
        listtype:1
    },true)
    .then(res=>{
        options.status = res.resultData.status;
    })
}

// 新增 编辑
const dateVisible = ref(false)
const uploadForm = reactive({
    method:'saveorupdate',
    t:'RISK_JUDGEMENT',
    id:'',
    type:'5',
    risk_judgement_date:dayjs().format('YYYY-MM-DD'),
})
const addOrUpdate = (id) =>{
  if(id==0){
        dateVisible.value = true
    }else{
      router.push('/QIDetail/'+id)
    }
}
const submit = () =>{
    http.post(uploadForm,true,false,false)
    .then(res=>{
        router.push('/QIDetail/'+res.resultData.map)
    })
    .catch(()=>{})
}
const showCalendar3 = ref(false);
const calendarOnConfirm3 = (date) =>{
    uploadForm.risk_judgement_date =  dayjs(date).format('YYYY-MM-DD');//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    showCalendar3.value = false;
}
// 删除
const del = (ids) =>{
    Dialog.confirm({
        message:'此操作将删除所选数据,请确认？',
    })
    .then(()=>{
        http.post({
            method:'delete',
            t:'RISK_JUDGEMENT',
            ids:ids
        },true,false,false,'delete')
        .then(()=>{
            onSearch(1);
        })
    })
    .catch(()=>{})
} 
const finished = ref(false)
const loading = ref(false);
const lodingmore = ref(false)
const onSearch = (page) =>{
    query.page = page;
    loading.value = false;
    Toast.loading({message: '加载中...',duration:0});
    http.get(query)
    .then(res=>{
        console.log(res)
        list.value = res.map;
        
    })
    .catch(()=>{
        Toast.clear();
    })
    // finished.value = true;
}
// 下拉刷新
const refresh = () =>{
    loading.value = true;
    onSearch(1)
}
// 
const onLoad = ()=>{

}
const calendarOnConfirm = (date) =>{
    query.startdate =  dayjs(date).format('YYYY-MM-DD');//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    showCalendar.value = false;
    // onSearch(1)
}
const calendarOnConfirm2 = (date) =>{
    query.enddate =  dayjs(date).format('YYYY-MM-DD');//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    showCalendar2.value = false;
    // onSearch(1)
}

onMounted(()=>{
    getSelectList()
    onSearch(1);
})
</script>